import React from 'react';
import BillingsUserPage from '../Pages/Configurations/Billings/BillingsUserPage';

const BillingRouter = [
  {
    path: '/billings',
    element: <BillingsUserPage />,
  }
];

export default BillingRouter;

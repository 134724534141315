/* eslint-disable react/prop-types */
import { Flex, CircularProgress, useColorMode, Stack, Text } from '@chakra-ui/react';
import React from 'react';

const ProgressCircle = ({ value }) => {

  const { colorMode } = useColorMode();

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor={colorMode === 'dark' ? 'blackAlpha.900' : 'rgba(255, 255, 255, 0.9)'}
      zIndex="9999"
      justifyContent="center"
      alignItems="center"
    >
      <Stack alignItems={'center'} spacing={2} justifyContent={'center'}>
        <Stack alignItems={'center'} spacing={1} justifyContent={'center'}>
          <Text fontWeight={1000} fontStyle={'revert'} textDecoration={'AppWorkspace'} fontSize={'lg'} letterSpacing={2} textTransform={'uppercase'}>deoapp</Text>
          <Text fontWeight={'500'} fontStyle={'revert'} fontSize={'xs'} letterSpacing={1} textTransform={'uppercase'}>all in one project management</Text>
        </Stack>
        <CircularProgress value={value} color='blue.500' size='80px' thickness='10px'>
          {/* <CircularProgressLabel>
            <Text fontWeight={500} fontStyle={'revert'} textDecoration={'AppWorkspace'} fontSize={'sm'} letterSpacing={1} textTransform={'uppercase'}>Waiting</Text>
          </CircularProgressLabel> */}
        </CircularProgress>
        <Stack alignItems={'center'} spacing={1} justifyContent={'center'}>
          <Text fontWeight={500} fontStyle={'revert'} textDecoration={'AppWorkspace'} fontSize={'sm'} letterSpacing={1} textTransform={'uppercase'}>{value}%</Text>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ProgressCircle;

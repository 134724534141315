/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Text,
  Image,
  Flex,
  Heading,
  HStack,
  Stack,
  Spacer,
  Divider,
  Button,
} from '@chakra-ui/react';
import { formatFrice } from '../../Utils/numberUtil';
import { IoMdArrowBack } from 'react-icons/io';

function OrderCustomerSuccesPayDrawer({ isOpen, onClose, orderData = null }) {
  
  return (
    <Drawer p={[1, 1, 5]} isOpen={isOpen} placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent h="100vh">
        <DrawerCloseButton onClick={() => onClose()} />

        <DrawerBody px={0}>
          <Container maxW="sm">
            <Flex
              direction="column"
              textAlign="center"
              justify="center"
              align="center"
            >

              <Text fontSize="xl" fontWeight="bold" mb={4}>
                                Pembayaran Berhasil ! Terimakasih sudah order.
              </Text>
              <Image
                src="https://img.freepik.com/free-vector/green-eco-loop-leaf-check-mark_78370-658.jpg?w=740&t=st=1687428827~exp=1687429427~hmac=d0e0cf300344fd7ebae0a884e6f0b699e133c40d2ca9015140399a3d0fe8ee6e"
                alt="Help Illustration"
                mb={4}
                w={{ base: '80%', lg: '200px' }}
              />
              <Box w="full" borderColor="green" py={2}>
                <Text fontWeight="bold">Please Screenshot the Summary </Text>
              </Box>
            </Flex>
            {orderData && (
              <Stack w="full" p="4" borderColor="gray.300" borderWidth={1} rounded="md" spacing={2}>
                <Heading size="sm" mb="2" align="center">
                                    Order Summary
                </Heading>
                <Flex direction="column">
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">ID Order:</Text>
                    <Spacer />
                    <Text textAlign="right">{orderData.id}</Text>
                  </HStack>
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Order Status:</Text>
                    <Spacer />
                    <Text textAlign="right">{orderData.orderStatus}</Text>
                  </HStack>
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">User ID:</Text>
                    <Spacer />
                    <Text textAlign="right" fontWeight={500}>{orderData?.userId}</Text>
                  </HStack>
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Order Name:</Text>
                    <Spacer />
                    <Text textAlign="right" fontWeight={500}>{orderData?.items[0]?.name}</Text>
                  </HStack>
                  {orderData?.payment_information && (
                    <HStack justifyContent="space-between" fontSize="sm" my={1}>
                      <Text fontWeight="bold">Payment Method:</Text>
                      <Spacer />
                      <Text textAlign="right" textTransform={'capitalize'}>{orderData?.payment_information?.method || ''} - {orderData?.payment_information?.bank_code || ''}</Text>
                    </HStack>
                  )}
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Module:</Text>
                    <Spacer />
                    <Text textAlign="right" textTransform={'uppercase'}>{orderData.module}</Text>
                  </HStack>
                  <HStack justifyContent="space-between" fontSize="sm" my={1} alignItems='center'>
                    <Text fontWeight="bold">Total Order:</Text>
                    <Spacer />
                    <Text textAlign="right" fontWeight={500}>
                                            Rp {formatFrice(orderData.amount)}
                    </Text>
                  </HStack>

                  <HStack justifyContent="space-between" fontSize="sm" my={1} alignItems='center'>
                    <Text fontWeight="bold">Discount:</Text>
                    <Spacer />
                    <Text textAlign="right" color={'red.300'} fontWeight={500}>
                                            Rp {formatFrice(orderData.discount || 0)}
                    </Text>
                  </HStack>
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Tax ({orderData.tax || 0}%) :</Text>
                    <Spacer />
                    <Text textAlign="right" fontWeight={500}>
                                            Rp{' '}
                      {formatFrice(
                        ((orderData.totalPrice - orderData.discount) * orderData.tax) / 100 || 0
                      )}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack justifyContent="space-between" fontSize="sm" my={2}>
                    <Text fontWeight="bold">Total Price:</Text>
                    <Spacer />
                    <Text textAlign="right" fontWeight={700} fontSize={'lg'}>
                                            Rp{' '}
                      {formatFrice((orderData?.amount || 0))}
                    </Text>
                  </HStack>
                </Flex>
                <Flex w='full'>
                  <Button w='full' borderRadius='lg' variant='outline' color='#F05A28' shadow='lg' borderColor="#F05A28" onClick={() => onClose()}>
                    <Flex flexDir='row' justifyContent='space-bewtween' alignItems='center'>
                      <IoMdArrowBack />
                      <Text>Kembali</Text>
                    </Flex>
                  </Button>
                </Flex>
              </Stack>
            )}
          </Container>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default OrderCustomerSuccesPayDrawer;

import React from 'react';
import ConfigurationPage from '../Pages/Configurations/ConfigurationPage';
import UsersPage from '../Pages/Configurations/Users/UsersPage';
import ProjectsPage from '../Pages/Configurations/Projects/ProjectPage';
import ProjectsViewPage from '../Pages/Configurations/Projects/ProjectViewPage';



const ConfigurationRouter = [
  {
    path: '/configuration',
    element: <ConfigurationPage />,
  },
  {
    path: '/configuration/users',
    element: <UsersPage />,
  },

  {
    path: '/configuration/projects',
    element: <ProjectsPage />,
  },
  {
    path: '/configuration/projects/:id',
    element: <ProjectsViewPage />,
  }
];

export default ConfigurationRouter;

import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from '../Layouts';
import SettingRouter from './SettingRouter';
import BillingRouter from './BillingRouter';



function MiddlewareRouter() {

  const allRouter = [
    ...SettingRouter,
    ...BillingRouter
  ];
  
  const navigate = useNavigate()


  useEffect(() => {
    const path = location.pathname;

    if (
      path !== '/settings' &&
      path !== '/notification' &&
      path !== '/change-password'
    ) {
      return navigate('/billings');
    }

  }, [location.pathname]);


  return (
    <Routes>

      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={<Layout>{item.element}</Layout>} />;
      })}
    </Routes>
  );
}

export default MiddlewareRouter;

/* eslint-disable react/prop-types */
import { Button, Divider, Heading,  HStack, Spacer, Stack, Tag, Text } from '@chakra-ui/react'
import React from 'react'
import { Rupiah } from '../../../Utils/NumberUtils'

function BillingsProductPage({ features, discount }) {



  const handleWhatsapp = () => {
    const message = encodeURIComponent('Hi Admin Deoapp for Enterprise,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊');
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=6281241678382&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  }


  return (
    <Stack alignItems={'center'} justifyContent="center" spacing={2}>
      <Text fontSize={'lg'} color="gray.600" textAlign={'center'}>Pick the right plan for your business</Text>{/* <AppSponsor /> */}
      <Heading>up to </Heading>

      {discount > 0 ? (
        <Stack>
          <HStack>
            <Tag bgColor={'red.600'} p={2}>
              <Heading fontWeight={'extrabold'} as="del" color={'white'}>{Rupiah(features?.price)}{features?.value === 120 ? '+' : ''}</Heading>
            </Tag>
          </HStack>

          <HStack>
            <Tag bgColor={'blue.600'} p={2}>
              <Heading fontWeight={'extrabold'} color={'white'}>{Rupiah(features?.price - (features?.price * discount / 100))}{features?.value === 120 ? '+' : ''}</Heading>
            </Tag>
            <Heading>All in</Heading>
          </HStack>
        </Stack>
      ) : (
        <HStack>
          <Tag bgColor={'blue.600'} p={2}>
            <Heading fontWeight={'extrabold'} color={'white'}>{Rupiah(features?.price)}{features?.value === 120 ? '+' : ''}</Heading>
          </Tag >
          <Heading>All in</Heading>
        </HStack >
      )
      }



      <Stack width='full'>
        <Heading textAlign='center' fontWeight={'bold'} fontSize='lg'>
          {features?.price !== 120 ?
            (Rupiah(features?.price - (features?.price * discount / 100)) + '/month')
            :
            <Button bgColor={'blue.600'} onClick={() => handleWhatsapp()} color="white">Contact Sales</Button>
          }
        </Heading>

       


        <Stack bgColor={'blue.600'} p={5} color="white" borderRadius={'lg'}>
          <Text>Details:</Text>
          {features?.details?.map((detail, index) => (
            <HStack key={index} spacing={2} alignItems="center">
              <Text color={'gray.100'}>{detail.key}:</Text>
              <Spacer />
              <Text fontWeight={'bold'}>{detail.value}</Text>
            </HStack>
          ))}
        </Stack>


      </Stack>
      <Divider />
    </Stack >
  )
}

export default BillingsProductPage